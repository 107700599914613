import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, GroupRepresentation, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';

@Component({
  selector: 'app-group-item-form',
  templateUrl: './group-item-form.component.html',
  styleUrls: ['./group-item-form.component.scss'],
})
export class GroupItemFormComponent extends BaseForm<GroupRepresentation> implements OnInit {
  selectedUsers;
  currentUsers: any[];
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.Group);
  }

  ngOnInit(): void {}

  getData() {
    return this.formGroup.getRawValue();
    // return this.viewModeService.viewMode == 'create' ? this.getDataKeyValueFormat() : this.getChangedFormValues().updateItems
  }

  setData(data: any) {
    this.selectedUsers = data?.members;
    this.patchFormData({ ...data });
    this.data = data;
    if (data?.name) {
      this.formGroup.controls.name.disable();
    }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      members: new FormControl(null),
      description: new FormControl(null),
    });
  }
  setUsers(event) {
    this.formGroup.patchValue({ members: event });
    this.currentUsers = [...event];
  }
}
